<template>
  
  <HomePage msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HomePage from './views/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>

</style>
