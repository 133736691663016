<template>
 <img alt="xiaoyanyun" class="zui-logo" :style="type=='xiaoyanyun'?{'width':'360px','height':'95px'}:''"  :src="src" @click="onClick"/>
</template>
<script>
  import './less/logo.less'

  export default {
    name: 'LogoComponent',
    props: {
      type: {
        default: 'xiaoyanyun'
      },
      href: {
        default: '/'
      }
    },
    data(){
         return {
          logo:require('./img/logo.png'),
           zlogo: require('./img/zlogo.png'),
 hrLogo: require('./img/hr-logo.png'),
 plusLogo: require( './img/plus-logo.png'),
mlogo: require( './img/m-logo.png'),
 mhrLogo: require( './img/m-hr-logo.png'),
 mplusLogo: require( './img/m-plus-logo.png'),

         }
    },
    computed: {
      src(){
        if (this.type === 'xiaoyanyun') {
          return this.logo
        }else if (this.type === 'zhongtai') {
          return this.zlogo
        }
       else  if (this.type === 'mzhichou') {
        return this.mlogo
        }
       else  if (this.type === 'hr') {
        return this.hrLogo
        }
        else if (this.type === 'mhr') {
          return this.mhrLogo
        }
       else  if (this.type === 'plus') {
        return this. plusLogo
        }
        else  {
          return this. mplusLogo
        }
        
      
    },
     }
   
  }
</script>
