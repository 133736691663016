<template>
  <div :class="cls">
    <slot></slot>

  </div>
</template>
<script>
  import './less/page.less'
  import '../assets/css/vivify.min.css'


  import Util from '../Util'

  export default {

 
    computed: {
      cls () {
        const cls = (Util.os.android || Util.os.iPhone) ? '' : 'min-1200'
        return 'zui-page ' + cls
      },
      toastText () {
        return this.$store.state.toastText
      },
     
    }
  }
</script>
