<template>
  <zui-page class="home-page">
    <zui-header>
   
      <zui-logo type="xiaoyanyun" href="/"></zui-logo>
        <h4 class="zui-logo-text" id="home">|&nbsp;&nbsp;三大主体融合
        
        
          <br/>|&nbsp;&nbsp;推动科技金融加速融合发展
        </h4>
         <div class="zui-rightcol" @click="openMenu" style="color: #0b8ba5;">◄目录►</div>
         <zui-button @click="onButtonClick('contact')">联系我们</zui-button>
         <!--<zui-button @click="onButtonClick('case')">典型案例</zui-button>-->
         <!--<zui-button @click="onButtonClick('plan')">解决方案</zui-button>-->
         <zui-button @click="onButtonClick('service')">关于我们</zui-button>
         <zui-button @click="onButtonClick('home')">首页</zui-button>
    </zui-header>

    <div class="banner vivify fadeIn">
      <div>
            <h1 class="vivify popIn">
        <span class="br">三大主体：</span>投资标的、投资机构、服务机构
      </h1>
      <h3 class="vivify popIn">“三体”发力，齐头并进，共创佳绩</h3>
    </div>

  </div>
    <div class="nav clear" id="service">
      <a class="zhichou vivify popIn">
        <div class="watermark blue">平台</div>
        <zui-logo type="zhongtai"></zui-logo>
        <zui-logo class="mobile" type="mzhichou"></zui-logo>
        <h3>解决投资机构难题</h3>
        <h4>线上了解更多项目信息>></h4>
      </a>
      <a class="hr vivify popIn delay-200">
        <div class="watermark green">专业</div>
        <zui-logo type="hr"></zui-logo>
        <zui-logo class="mobile" type="mhr"></zui-logo>
        <h3>节约寻找资源时间</h3>
        <h4>灵活·专业·快速>></h4>
      </a>
      <a class="plus vivify popIn delay-400">
        <div class="watermark blue">保障</div>
        <zui-logo type="plus"></zui-logo>
        <zui-logo class="mobile" type="mplus"></zui-logo>
        <h3>保障企业无间断运营</h3>
        <h4>后顾无忧·获取更多定制化信息>></h4>
      </a>
    </div>
   <!-- <a class="join-zhichou" href="http://wx.zhichou.com/#/me/resume">
      我是牛人，入驻智筹 >>
    </a> -->
    <div class="service" >
      <h1>
        专业知识研发集成平台<br/>
        解决你的业务难题
      </h1>
      <h3>
        广东金融高新区科技金融投资服务平台
      </h3>
      <h4>协助科创企业和项目系统对接聚集在千灯湖创投小镇的1000多家基金
  <br/>

      股东包括季华实验室、仙湖实验室、南海产投、珠湾大数据和高成长投资等机构
      </h4>
    </div>
    <div class="features" style="display:flex;justify-content: center;">
     <div> <ul>
        <li>
          <img :src="feature1"/>
          <h4>专业咨询</h4>
          <p>凭借多年金融行业经验，为您提供特定的商业模式咨询。</p>
        </li>
        <li>
          <img :src="feature2"/>
          <h4>专业测评</h4>
          <p>多维度、全方位的测评，按照不同发展阶段进行画像分级，标识其金融发展需求。</p>
        </li>
       
        <li>
          <img :src="feature4"/>
          <h4>专业情报</h4>
          <p>提供定制化科技情报信息服务。</p>
        </li>
     </ul></div>
     <div style="display: flex;align-items: center;    flex-direction: column;
    justify-content: center;margin-left:100px;" id="contact">
      <img src="../assets/img/qrcode.jpg" style="width: 200px; height: 200px;">
      <p style="margin-top:20px" >了解更多资讯，请关注我们</p> 
    </div>
    </div>
    <!-- <div class="customer-case-logo">
       <img :src="companyLogo"/>
       <img class="mobile" :src="mcompanyLogo"/>
     </div> -->
     <div class="apply-hr">
       <h2>
        千灯湖科技平台，提供优质服务
       </h2>
       <h4>
         为企业解决融资、技术、人才难的问题，定制推送业务领域的信息化情报
       </h4>
     </div>
    <zui-footer/>
  </zui-page>
</template>
<script>
  import './less/home-page.less'
  import ZuiLogo from '../components/LogoComponent'
  import ZuiPage from '../components/PageComponent'
  import ZuiHeader from '../components/HeaderComponent'
  import ZuiFooter from '../components/FooterComponent'
  import ZuiButton from '../components/ButtonComponent'
  import feature1 from '../assets/img/feature1.png'
  import feature2 from '../assets/img/feature2.png'
  import feature3 from '../assets/img/feature3.png'
  import feature4 from '../assets/img/feature4.png'
  import Util from '../Util'

  export default{
    components: {ZuiPage, ZuiLogo, ZuiHeader, ZuiFooter,ZuiButton},
    data () {
      return {
        feature1: feature1,
        feature2: feature2,
        feature3: feature3,
        feature4: feature4,
        carouselPerPage: (Util.os.android || Util.os.iPhone) ? 1 : 2,
        configData : {
            position: {  // 设置显示位置，position
              top: '60px',
              right: '15px',
              bottom: '',
              left: ''
            },
            width: '40%', // 设置宽度
            list: [ // 设置下拉列表数据和对应的点击事件
              {text: '首页', action: this.gohome},
              {text: '我的服务', action: this.goservice},
              //{text: '解决方案', action: this.goplan},
              //{text: '典型案例', action: this.gocase},
              {text: '联系我们', action: this.golink}
            ]
        }
      }
    },
   methods: {
      onButtonClick: function (e) {
        if(e=="home"){
            this.$router.push({path:'/'})
        }else if(e=="service"){
       
   document.querySelector("#service").scrollIntoView(true)
        }else if(e=="contact"){
          document.querySelector("#contact").scrollIntoView(true)
        }else{
            this.$router.push({path:'/'})
        }
      },
     
      gohome: function () {
       this.$router.push({path:'/'})
      },
      goservice: function () {
       this.$router.push({path:'/service'})
      },
      goplan: function () {
       this.$router.push({path:'/plan'})
      },
      gocase: function () {
       this.$router.push({path:'/case'})
      },
      golink: function () {
       this.$router.push({path:'/link'})
      },
    }

  }
</script>
