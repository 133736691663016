<template>
  <header class="zui-header">
    <slot></slot>
  </header>
</template>
<script>
  import './less/header.less'

  export default {
    props: {
      logoType: {
        default: 'zhichou'
      }
    },
    
  }
</script>
