<template>
  <footer class="zui-footer">
    <!--<div class="links">
      <a href="/">回到首页</a>
      <a href="/case">成功案例</a>
      <a href="/link">联系我们</a>
    </div>-->
    <p class="copy-right" >
      2020-2045 © 千灯湖科技平台 版权所有.
      <br>
      <a style="color:#fff" href="https://beian.miit.gov.cn" target="_blank">  粤ICP备2022153560号-1</a>
    </p>
  </footer>
</template>
<script>
  import './less/footer.less'
  export default {}
</script>
